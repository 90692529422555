import React from "react"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FeedbackHeaderBool from "../../../../../components/feedback-header-bool"
import { getCourseNavigation } from "../../../../../store/courses"
import Paragraph from "../../../../../components/paragraph"
import QuotePrimary from "../../../../../components/quote-primary"

const Page = () => {
  const { getTask, getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  const taskProps = {
    courseId: "hoelderlins-reisen",
    chapterId: "02-reiseerinnerung",
    taskId: "handschrift-entziffern",
  }

  const task = getTask(taskProps)
  const answer = getAnswer({
    ...taskProps,
    taskId: "handschrift-entziffern-stadt",
  })

  const isValid =
    answer?.stadt?.trim().toLowerCase() === task.solution.toLowerCase()

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          label="Weiter"
          to="/kurse/hoelderlins-reisen/02-reiseerinnerung/manuskript-erforschen"
        />
      }
    >
      <Seo title="Hölderlins Handschrift entziffern" />
      <Stack>
        <FeedbackHeaderBool
          confirmed={isValid}
          title="Im Gedicht geht es um die Stadt Heidelberg."
        />
        <Paragraph>Es beginnt mit den Zeilen:</Paragraph>
        <QuotePrimary>
          <p>Lange lieb ich dich schon, möchte dich, mir zur Lust,</p>
          <p>Mutter nennen und dir schenken ein kunstlos Lied,</p>
          <p>Du der Vaterlandsstädte</p>
          <p>Ländlichschönste, so viel ich sah!</p>
        </QuotePrimary>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
